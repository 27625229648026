
    import { defineComponent } from "vue";
    import vm from '@/main';
    import WorkspaceCourseApi from "@/apis/WorkspaceCourseApi";
    import WorkspaceQuizApi from "@/apis/WorkspaceQuizApi";
    import WorkspaceSellplanApi from "@/apis/WorkspaceSellPlanApi";
    import { courseModel} from "@/models/WorkspaceModel";
    import { sellPlanModel} from "@/models/WorkspaceModel";
    import { quizModel} from "@/models/WorkspaceModel";
    import CategoryList from "@/components/CategoryList.vue";
    // import RandomQuiz from "@/components/RandomQuiz.vue";


    export default defineComponent({
        name: "Secondnavbar",
        components: {
           CategoryList
        },
        props: {
            stayIsFree: {
                type: Boolean,
                required: false,
                default: null,
            },
            stayIsQuiz: {
                type: Boolean,
                required: false,
                default: null,
            },
        },
        data() {
            return {
                displayCategoryArray: [] as any[],
                beClickLevelList: [] as any[],
                newCategoryName:'',
                canInput: false,
                modalShow: false,
                keyword:"",
                clickIsFree: false,
                clickIsExamCategory: false,
                currentCourse: {
                    metadata: {name: "未命名課程",type: "" },
                    imageUrl: '',
                    uuid: '',
                    courseFile:'',
                    categoryNodeId: '',
                    CategoryNodePath: ''
                } as courseModel,
                currentSellPlan: {
                    price: 0,
                    sellType: 'COURSE',
                    showImageUrl: '',
                    isRandom: false,
                    uuid: '',
                    isUserChoose: false,
                    userChooseNumber: 0,
                    isBigCourse: false,
                    isDisplay: true,
                    metadata: {name: "未命名銷售方案",type: "" },
                    timeLimit: 0,
                    numberLimit: 0,
                    description: '',
                    categoryIds: [],
                    categoryPaths: [],
                } as sellPlanModel,
                currentquiz: {
                    imageUrl: '',
                    uuid: '',
                    metadata: {name: "未命名測驗",type: "" },
                    shuffleOptions: false,
                    shuffleQuizzes: false,
                    categoryIds: '',
                    CategoryNodePath: ''
                } as quizModel,
            };
        },
        compute: {
        },
        methods:{
            handleModalClose(){
                console.log("Debug: handleModalClose");
                this.modalShow = false
            },
            changeToCourse(){
                if(this.$router.currentRoute.path.includes('course')){
                    this.clickIsFree = false
                    this.$emit('clickIsFree', this.clickIsFree)
                    this.$router.push({
                        name: "CourseWorkspace",
                    });
                }
            },
            changeToFree(){
                this.clickIsFree = true
                this.$emit('clickIsFree', this.clickIsFree)
                this.$router.push({
                    name: "FreeCourseWorkspace",
                });
            },
            changeToQuizCat(){

                this.clickIsExamCategory = true
                this.$emit('clickIsExamCategory', this.clickIsExamCategory)
                this.$router.push({
                    name: "QuizCategoryWorkspace",
                });
                this.clearKeyword()

            },
            changeToCourseCat(){
                if(this.$router.currentRoute.path.includes('category')){
                    this.clickIsExamCategory = false
                    this.$emit('clickIsExamCategory', this.clickIsExamCategory)
                    this.$router.push({
                        name: "CourseCategoryWorkspace",
                    });
                    this.clearKeyword()

                }
            },
            async addNew(){
                if(this.$router.currentRoute.path.includes('course')){
                    try{
                        this.$store.commit('updateLoading', true);
                        const parentId = vm.$cookies.get('courseFolders')
                        const createCourse = JSON.parse(JSON.stringify(await WorkspaceCourseApi.createCourse("未命名課程",parentId)));
                        this.currentCourse.uuid = createCourse.uuid

                        this.$store.commit('changeCourseWorkspace', this.currentCourse);
                        vm.$router.push('/workspace/course/create');
                    }catch(err){
                        console.log(err);
                    }finally{
                        this.$store.commit('updateLoading', false);
                    }
                }
                else if(this.$router.currentRoute.path.includes('quiz')){
                    try{
                        this.$store.commit('updateLoading', true);
                        const parentId = vm.$cookies.get('quizFolders')
                        const createQuiz = JSON.parse(JSON.stringify(await WorkspaceQuizApi.createQuiz("未命名測驗",parentId)));
                        this.currentquiz.uuid = createQuiz.uuid;
                        this.$store.commit('changeQuizWorkspace', this.currentquiz);
                        vm.$router.push('/workspace/quiz/create');
                    }catch(err){
                        console.log(err);
                    }finally{
                        this.$store.commit('updateLoading', false);
                    }
                }
                else if(this.$router.currentRoute.path.includes('sellPlan')){
                    try{
                        this.$store.commit('updateLoading', true);
                        const parentId = vm.$cookies.get('sellPlanFolders')
                        const createSellPlan = JSON.parse(JSON.stringify(await WorkspaceSellplanApi.createSellPlan("未命名銷售方案",parentId)));
                        this.currentSellPlan.uuid = createSellPlan.uuid;
                        console.log("type: "+this.currentSellPlan.sellType);
                        this.$store.commit('changeSellPlanWorkspace', this.currentSellPlan);
                        vm.$router.push('/workspace/sellPlan/create');
                    }catch(err){
                        console.log(err);
                    }finally{
                        this.$store.commit('updateLoading', false);
                    }
                }
                else if(this.$router.currentRoute.path.includes('category')){
                    try{
                        this.modalShow = true
                    }catch(err){
                        console.log(err);
                    }finally{
                        this.$store.commit('updateLoading', false);
                    }
                }
            },
            buttonSearch() {
                console.log("keyword = "+this.keyword);
                // var message = encodeURI(this.keyword)
                this.$emit('buttonSearch', this.keyword);
            },
            clearKeyword(){
                this.keyword = "";
                console.log("clear keyword");
                this.$emit('clearKeyword');
            },
            getContext(s:string) {
                if(s == 'title'){
                    if(this.$router.currentRoute.path.includes('quiz')){
                        return '我的測驗'
                    }
                    else if(this.$router.currentRoute.path.includes('sellPlan')){
                        return '我的銷售方案'
                    }
                    else if(this.$router.currentRoute.path.includes('category')){
                        return '我的課程分類'
                    }
                    else{
                        return '我的課程'
                    }
                }
                else if(s == 'label'){
                    if(this.$router.currentRoute.path.includes('course')){
                        return '課程'
                    }
                    if(this.$router.currentRoute.path.includes('quiz')){
                        return '測驗'
                    }
                    if(this.$router.currentRoute.path.includes('sellPlan')){
                        return '銷售方案'
                    }
                    if(this.$router.currentRoute.path.includes('category')){
                        return '分類'
                    }
                }
            }
        },
        created(){
            this.clickIsFree = this.stayIsFree
            this.clickIsExamCategory = this.stayIsQuiz
            console.log("clickIsExamCategory = ", this.clickIsExamCategory)

        }
    });
